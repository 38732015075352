/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createACECEvent = /* GraphQL */ `
  mutation CreateACECEvent(
    $input: CreateACECEventInput!
    $condition: ModelACECEventConditionInput
  ) {
    createACECEvent(input: $input, condition: $condition) {
      id
      type
      title
      description
      startDate
      startTime
      endDate
      endTime
      location
      imageKey
      signUps {
        nextToken
      }
      organizer
      participants {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateACECEvent = /* GraphQL */ `
  mutation UpdateACECEvent(
    $input: UpdateACECEventInput!
    $condition: ModelACECEventConditionInput
  ) {
    updateACECEvent(input: $input, condition: $condition) {
      id
      type
      title
      description
      startDate
      startTime
      endDate
      endTime
      location
      imageKey
      signUps {
        nextToken
      }
      organizer
      participants {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteACECEvent = /* GraphQL */ `
  mutation DeleteACECEvent(
    $input: DeleteACECEventInput!
    $condition: ModelACECEventConditionInput
  ) {
    deleteACECEvent(input: $input, condition: $condition) {
      id
      type
      title
      description
      startDate
      startTime
      endDate
      endTime
      location
      imageKey
      signUps {
        nextToken
      }
      organizer
      participants {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEventPartipant = /* GraphQL */ `
  mutation CreateEventPartipant(
    $input: CreateEventPartipantInput!
    $condition: ModelEventPartipantConditionInput
  ) {
    createEventPartipant(input: $input, condition: $condition) {
      id
      mainParticipant {
        id
        type
        lastName
        firstName
        chineseName
        englishName
        preferredName
        email
        backupEmail
        mainPhone
        otherPhone
        weChatId
        invitedBy
        companyName
        companyAddress
        operationAreaUSA
        operationAreaChina
        operationAreaOther
        operationAreaOtherInput
        companyWebsite
        industry
        businesDescription
        featured
        approved
        imageKey
        bio
        createdAt
        updatedAt
        owner
      }
      otherParticipants
      createdAt
      updatedAt
      aCECEventParticipantsId
      eventPartipantMainParticipantId
      owner
    }
  }
`;
export const updateEventPartipant = /* GraphQL */ `
  mutation UpdateEventPartipant(
    $input: UpdateEventPartipantInput!
    $condition: ModelEventPartipantConditionInput
  ) {
    updateEventPartipant(input: $input, condition: $condition) {
      id
      mainParticipant {
        id
        type
        lastName
        firstName
        chineseName
        englishName
        preferredName
        email
        backupEmail
        mainPhone
        otherPhone
        weChatId
        invitedBy
        companyName
        companyAddress
        operationAreaUSA
        operationAreaChina
        operationAreaOther
        operationAreaOtherInput
        companyWebsite
        industry
        businesDescription
        featured
        approved
        imageKey
        bio
        createdAt
        updatedAt
        owner
      }
      otherParticipants
      createdAt
      updatedAt
      aCECEventParticipantsId
      eventPartipantMainParticipantId
      owner
    }
  }
`;
export const deleteEventPartipant = /* GraphQL */ `
  mutation DeleteEventPartipant(
    $input: DeleteEventPartipantInput!
    $condition: ModelEventPartipantConditionInput
  ) {
    deleteEventPartipant(input: $input, condition: $condition) {
      id
      mainParticipant {
        id
        type
        lastName
        firstName
        chineseName
        englishName
        preferredName
        email
        backupEmail
        mainPhone
        otherPhone
        weChatId
        invitedBy
        companyName
        companyAddress
        operationAreaUSA
        operationAreaChina
        operationAreaOther
        operationAreaOtherInput
        companyWebsite
        industry
        businesDescription
        featured
        approved
        imageKey
        bio
        createdAt
        updatedAt
        owner
      }
      otherParticipants
      createdAt
      updatedAt
      aCECEventParticipantsId
      eventPartipantMainParticipantId
      owner
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      location
      description
      image
      owner
      postedAt
      type
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      location
      description
      image
      owner
      postedAt
      type
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      location
      description
      image
      owner
      postedAt
      type
      createdAt
      updatedAt
    }
  }
`;
export const createSignUp = /* GraphQL */ `
  mutation CreateSignUp(
    $input: CreateSignUpInput!
    $condition: ModelSignUpConditionInput
  ) {
    createSignUp(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        type
        title
        description
        startDate
        startTime
        endDate
        endTime
        location
        imageKey
        organizer
        createdAt
        updatedAt
        owner
      }
      firstName
      lastName
      note
      phone
      email
      owner
      referredBy
      guestNumber
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateSignUp = /* GraphQL */ `
  mutation UpdateSignUp(
    $input: UpdateSignUpInput!
    $condition: ModelSignUpConditionInput
  ) {
    updateSignUp(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        type
        title
        description
        startDate
        startTime
        endDate
        endTime
        location
        imageKey
        organizer
        createdAt
        updatedAt
        owner
      }
      firstName
      lastName
      note
      phone
      email
      owner
      referredBy
      guestNumber
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteSignUp = /* GraphQL */ `
  mutation DeleteSignUp(
    $input: DeleteSignUpInput!
    $condition: ModelSignUpConditionInput
  ) {
    deleteSignUp(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        type
        title
        description
        startDate
        startTime
        endDate
        endTime
        location
        imageKey
        organizer
        createdAt
        updatedAt
        owner
      }
      firstName
      lastName
      note
      phone
      email
      owner
      referredBy
      guestNumber
      type
      createdAt
      updatedAt
    }
  }
`;
export const createPastEvent = /* GraphQL */ `
  mutation CreatePastEvent(
    $input: CreatePastEventInput!
    $condition: ModelPastEventConditionInput
  ) {
    createPastEvent(input: $input, condition: $condition) {
      id
      eventName
      date
      location
      description
      owner
      eventPoster
      type
      images
      videos
      createdAt
      updatedAt
    }
  }
`;
export const updatePastEvent = /* GraphQL */ `
  mutation UpdatePastEvent(
    $input: UpdatePastEventInput!
    $condition: ModelPastEventConditionInput
  ) {
    updatePastEvent(input: $input, condition: $condition) {
      id
      eventName
      date
      location
      description
      owner
      eventPoster
      type
      images
      videos
      createdAt
      updatedAt
    }
  }
`;
export const deletePastEvent = /* GraphQL */ `
  mutation DeletePastEvent(
    $input: DeletePastEventInput!
    $condition: ModelPastEventConditionInput
  ) {
    deletePastEvent(input: $input, condition: $condition) {
      id
      eventName
      date
      location
      description
      owner
      eventPoster
      type
      images
      videos
      createdAt
      updatedAt
    }
  }
`;
export const createInqury = /* GraphQL */ `
  mutation CreateInqury(
    $input: CreateInquryInput!
    $condition: ModelInquryConditionInput
  ) {
    createInqury(input: $input, condition: $condition) {
      id
      name
      email
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateInqury = /* GraphQL */ `
  mutation UpdateInqury(
    $input: UpdateInquryInput!
    $condition: ModelInquryConditionInput
  ) {
    updateInqury(input: $input, condition: $condition) {
      id
      name
      email
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInqury = /* GraphQL */ `
  mutation DeleteInqury(
    $input: DeleteInquryInput!
    $condition: ModelInquryConditionInput
  ) {
    deleteInqury(input: $input, condition: $condition) {
      id
      name
      email
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      type
      lastName
      firstName
      chineseName
      englishName
      preferredName
      email
      backupEmail
      mainPhone
      otherPhone
      weChatId
      invitedBy
      companyName
      companyAddress
      operationAreaUSA
      operationAreaChina
      operationAreaOther
      operationAreaOtherInput
      companyWebsite
      industry
      businesDescription
      featured
      approved
      imageKey
      bio
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      type
      lastName
      firstName
      chineseName
      englishName
      preferredName
      email
      backupEmail
      mainPhone
      otherPhone
      weChatId
      invitedBy
      companyName
      companyAddress
      operationAreaUSA
      operationAreaChina
      operationAreaOther
      operationAreaOtherInput
      companyWebsite
      industry
      businesDescription
      featured
      approved
      imageKey
      bio
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      type
      lastName
      firstName
      chineseName
      englishName
      preferredName
      email
      backupEmail
      mainPhone
      otherPhone
      weChatId
      invitedBy
      companyName
      companyAddress
      operationAreaUSA
      operationAreaChina
      operationAreaOther
      operationAreaOtherInput
      companyWebsite
      industry
      businesDescription
      featured
      approved
      imageKey
      bio
      createdAt
      updatedAt
      owner
    }
  }
`;
