import { useState, useCallback, useEffect } from "react";
import HomeContainer from "../components/HomeContainer";
import Footer from "../components/Footer";
import styles from "./MemberPage.module.css";
import regstyles from "./RegistrationForm.module.css";
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import { v4 as uuid } from 'uuid';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { Storage, API } from 'aws-amplify';
import { createMember } from "../graphql/mutations";
import { Amplify } from 'aws-amplify';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import awsExports from '../aws-exports';
Amplify.configure(awsExports);


const defaultValues = {
  lastName: "",
  firstName: "",
  chineseName: "",
  englishName: "",
  preferredName: "",
  email: "",
  backupEmail: "",
  mainPhone: "",
  otherPhone: "",
  weChatId: "",
  invitedBy: "",
  companyName: "",
  companyAddress: "",
  operationAreaUSA: true,
  operationAreaChina: false,
  operationAreaOther: false,
  operationAreaOtherInput: "",
  companyWebsite: "",
  industry: "",
  businesDescription: "",
  featured: "",
  approved: "",
  bio: "",
  file: "",
}
const validationSchema = yup.object().shape({
  englishName: yup.string().min(4).required("Required"),
  email: yup.string().email().required("Required"),

});

const MemberPage = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { isSubmitSuccessful, errors } } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues
    });
  const [openRegistered, setOpenRegistered] = useState(false);
  const [mainPhone, setMainPhone] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [image, setImage] = useState({});


  const handleClose = () => {
    setOpenRegistered(false);
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [formState, reset]);

  const addMember = async (data) => {
    const RegEx = /^(\+)|\D/g
    const cleanedPhone = `+${mainPhone.replace(RegEx, '')}`;
    let otherPhone = '';
    if (secondPhone) {
      otherPhone = `+${secondPhone.replace(RegEx, '')}`;
    }
    console.log(JSON.stringify(data));
    const lastName = data.lastName;
    const firstName = data.firstName;
    const englishName = data.englishName;
    const preferredName = data.preferredName
    const email = data.email;
    const backupEmail = data.backupEmail;
    const weChatId = data.weChatId;
    const invitedBy = data.invitedBy;
    const companyName = data.companyName;
    const companyAddress = data.companyAddress;
    const operationAreaChina = data.operationAreaChina;
    const operationAreaOther = data.operationAreaOther;
    const operationAreaUSA = data.operationAreaUSA;
    const operationAreaOtherInput = data.operationAreaOtherInput;
    const companyWebsite = data.companyWebsite;
    const industry = data.industry;
    const businesDescription = data.businesDescription;

    const memberInfo = {
      lastName, firstName, englishName, preferredName,
      email, backupEmail,
      weChatId, invitedBy, companyName, companyAddress,
      operationAreaChina,
      operationAreaOther, operationAreaUSA,
      operationAreaOtherInput, companyWebsite,
      industry, businesDescription,
      type: "member",
      mainPhone: cleanedPhone,
      otherPhone: otherPhone,
      chineseName: `${data.lastName} ${data.firstName}`,
      approved: 'N',
      featured: 'N',
      imageKey: image.name,
    }
    try {
      console.log("Inside addMember()=" + JSON.stringify(memberInfo))

      await Storage.put(
        image.name,
        image.fileInfo,
        { level: "public" }
      );
      const res = await API.graphql({
        query: createMember,
        variables: { input: memberInfo },
        authMode: 'API_KEY'
      });
      console.log(res)
      setOpenRegistered(true);
      setMainPhone("");

      setSecondPhone("");
      setImage(null);
    } catch (e) {
      console.log("error=", e)
    }

  }
  const onSubmit = data => {
    console.log(data);
    addMember(data)

  }
  const handleOnChange = value => {
    setMainPhone(value);
  };
  const handleSecondOnChange = value => {
    setSecondPhone(value);
  };
  function onChangeFile(e) {
    e.preventDefault();
    if (!e.target.files[0]) return;
    const fileExtPosition = e.target.files[0].name.search(/.png|.jpg|.gif/i);
    const firstHalf = e.target.files[0].name.slice(0, fileExtPosition);
    const secondHalf = e.target.files[0].name.slice(fileExtPosition);
    const fileName = firstHalf + "_" + uuid() + secondHalf;
    console.log(fileName);
    const image = { fileInfo: e.target.files[0], name: fileName };
    setImage(image);
  }

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/aboutuspage");
  }, [navigate]);


  return (
    <>
      <div className={styles.memberpage}>
        <HomeContainer
          headerMenuOptionsRowBackgroundImage={`url("/headerlogo8.svg")`}
          onHomeClick={onHomeClick}
          onEventsClick={onEventsClick}
          onAboutUsClick={onAboutUsClick}
        />
        <div className={styles.aboutussection}>
          <div className={styles.missionsectionParent}>
            <div className={styles.missionsection}>
              <div className={styles.theAtlantaChinese}>
                The Atlanta Chinese Entrepreneurs Club (ACEC)
              </div>
              <div className={styles.establishedIn2015Container}>
                <p className={styles.establishedIn2015}>
                  Established in 2015, the Atlanta Entrepreneurs Club is an
                  independently operated non-profit organization that provides a
                  platform for entrepreneurs, including Chinese and other local
                  entrepreneurs in Atlanta and surrounding areas, to exchange
                  business information, learn about enterprise management, seek
                  advice on entrepreneurship and management consulting, and
                  enjoy leisure and entertainment services.
                </p>
              </div>
            </div>
            <div className={regstyles.form}>
              <Container>
                <Grid container spacing={3} alignItems="center"
                  justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={12}

                    ml={{ xs: "auto", lg: 6 }}
                    mr={{ xs: "auto", lg: 6 }}
                    align="center"
                  >


                    <Box p={1}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Box width="100%" >
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="中文姓 Chinese last name"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required
                                {...register("lastName")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="中文名 Chinese first name"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required
                                {...register("firstName")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="英文名 English Name, First + Last Name"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                helperText={errors.englishName?.message}
                                error={!!errors.englishName?.message}
                                {...register("englishName")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="常用称呼 Preferred Name"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...register("preferredName")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField

                                variant="standard"
                                label="常用电子邮箱 Email"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required
                                helperText={errors.email?.message}
                                error={!!errors.email?.message}
                                {...register("email")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField

                                variant="standard"
                                label="备用电子邮箱 Backup Email"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                helperText={errors.backupEmail?.message}
                                error={!!errors.backupEmail?.message}
                                {...register("backupEmail")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="body2" >
                                美国手机号码 Phone number*
                              </Typography>

                              <PhoneInput
                                defaultCountry={"US"}
                                onChange={handleOnChange}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="body2" >
                                座机号码 Other Phone Number
                              </Typography>
                              <PhoneInput
                                defaultCountry={"US"}
                                onChange={handleSecondOnChange}

                              />

                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="微信号 WeChat ID"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...register("weChatId")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="邀请人 Invited by"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...register("invitedBy")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="公司名称 Company Name"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required
                                {...register("companyName")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="公司地址 Company Address"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...register("companyAddress")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField


                                variant="standard"
                                label="公司网站 Company Website"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...register("companyWebsite")}

                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="standard"
                                label="公司所属行业 Industry*"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required
                                {...register("industry")}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Typography variant="Caption" color="text" mb={3}>
                                公司经营所在地 Area of Operation*
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                type="checkbox"
                                variant="standard"
                                label="美国 USA"
                                fullWidth
                                {...register("operationAreaUSA")}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                type="checkbox"
                                variant="standard"
                                label="中国 China"
                                fullWidth
                                {...register("operationAreaChina")}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                type="checkbox"
                                variant="standard"
                                label="其他 Other"
                                fullWidth
                                {...register("operationAreaOther")}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                fullWidth

                                {...register("operationAreaOtherInput")}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                variant="standard"
                                label="主营业务描述 Business Description"
                                placeholder="Describe your company business"
                                InputLabelProps={{ shrink: true }}
                                multiline
                                fullWidth
                                rows={6}
                                {...register("businesDescription")}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="Caption" color="text" mb={3}>
                                Upload Company Logo*
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label htmlFor="contained-button-file">
                                <input accept="image/*" id="file" type="file" onChange={onChangeFile} />
                              </label>
                            </Grid>
                          </Grid>
                          <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                            <Grid item xs={12} md={6}>
                              <Button type="reset" variant="contained" color="info" sx={{ padding: 1, margin: 2 }} onClick={() => reset()}>
                                Clear
                              </Button>

                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Button type="submit" variant="contained" color="info" sx={{ padding: 1, margin: 2 }}>
                                Submit
                              </Button>
                            </Grid>

                          </Grid>
                        </Box>
                      </form>
                    </Box>

                  </Grid>
                </Grid>
              </Container>
            </div>
            <div>
              <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={openRegistered}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Welcome to ACEC!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    A confirmation email is sent to your email. We will contact you soon. Please contact info@acec.club for any questions.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={handleClose} autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog></div>
          </div>
        </div>
      </div>
      <Footer productId="/frame-19927.svg" />

    </>
  );
};

export default MemberPage;
