/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://jm4faujpnh.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2gfzbtficzbq5gtcjckwzdtjye.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vtobtjdokng7rp3xytqmrfj22e",
    "aws_cognito_identity_pool_id": "us-east-1:cd661d8a-b425-4736-b7ca-5f175af03ea3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EDi8Z9bTC",
    "aws_user_pools_web_client_id": "7h3h2lhuepve95pdj42ukl9fgl",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "acecsite-20220706170257-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1ip4wrxumiyi4.cloudfront.net",
    "aws_user_files_s3_bucket": "acecsiteded74228e20f4ad3b239013051cdf228233931-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
