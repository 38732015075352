import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeContainer from "../components/HomeContainer";
import EventCardContainer from "../components/EventCardContainer";
import Footer from "../components/Footer";
import styles from "./PastEventPage.module.css";
import protectedRoute from '../protectedRoute'

const PastEventPage = () => {
  const navigate = useNavigate();


  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  return (
    <>
      <div className={styles.pasteventpage}>
        <HomeContainer
          onHomeClick={onHomeClick}
          onEventsClick={onEventsClick}
          onMembershipClick={onMembershipClick}
        />
        <div className={styles.pasteventsection}>
          <div className={styles.pasteventtitlesection}>
            <b className={styles.discoverTheWorld}>For the past five years</b>
            <div className={styles.discoverTheWorld1}>ACEC Past Events</div>
          </div>
          <div className={styles.recCardsContainer}>
            <EventCardContainer />
            <EventCardContainer />
            <EventCardContainer />
            <EventCardContainer />
          </div>
        </div>
        <Footer productId="/frame-19921.svg" />
      </div>

    </>
  );
};

export default protectedRoute(PastEventPage);
