import styles from "./Footer.module.css";
const Footer = ({ productId }) => {
  return (
    <div className={styles.footer}>
      <div className={styles.leftframe}>
        <div className={styles.acec}>ACEC</div>
        <label className={styles.copyrightInformation}>
          © 2023, ACEC. All Rights Reserved.
        </label>
      </div>
      <div className={styles.rightframe}>
        <div className={styles.address}>
          <div className={styles.contactUsWrapper}>
            <button className={styles.contactUs}>Contact Us</button>
          </div>
          <button className={styles.nBerkeleyLake}>
            2700 N Berkeley Lake Rd NW
          </button>
          <button className={styles.nBerkeleyLake}>Duluth, GA 30096</button>
        </div>
        <div className={styles.email}>
          <div className={styles.frameParent}>
            <img className={styles.frameChild} alt="" src={productId} />
            <button className={styles.contactUs}>Email us</button>
          </div>
          <button className={styles.nBerkeleyLake}>info@acec.club</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
