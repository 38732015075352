import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeContainer from "../components/HomeContainer";
import Footer2 from "../components/Footer";
import styles from "./EventPage.module.css";

const EventPage = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/aboutuspage");
  }, [navigate]);

  const onEventCardClick = useCallback(() => {
    navigate("/eventdetail4page");
  }, [navigate]);

  const onEventCard1Click = useCallback(() => {
    navigate("/event1detailpage");
  }, [navigate]);

  const onEventCard2Click = useCallback(() => {
    navigate("/event2detailpage");
  }, [navigate]);

  const onEventCard3Click = useCallback(() => {
    navigate("/event3detailpage");
  }, [navigate]);

  return (
    <div className={styles.eventpage}>
      <HomeContainer
        headerMenuOptionsRowBackgroundImage="url('/aceclogo@3x.png')"
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
        onAboutUsClick={onAboutUsClick}
      />
      <div className={styles.eventframe}>
        <div className={styles.eventcard} onClick={onEventCardClick}>
          <div className={styles.divinfoDateBox}>
            <b className={styles.b}>30</b>
            <b className={styles.dec}>DEC</b>
            <div className={styles.am}>6:00 AM</div>
          </div>
          <img className={styles.icon} alt="" src="/4802775@2x.png" />
          <div className={styles.divcontentRight}>
            <div className={styles.atlanta2024International}>
              ATLANTA 2024 International New Year Concert
            </div>
          </div>
        </div>

        <div className={styles.eventcard2} onClick={onEventCard2Click}>
          <div className={styles.divinfoDateBox2}>
            <b className={styles.b2}>20</b>
            <b className={styles.dec}>MAY</b>
            <div className={styles.pm}>12:00 PM</div>
          </div>
          <img className={styles.icon} alt="" src="/48027752@2x.png" />
          <div className={styles.divcontentRight}>
            <div className={styles.atlanta2024International}>
              ACEC Fortune Salon
            </div>
          </div>
        </div>
        <div className={styles.eventcard} onClick={onEventCard3Click}>
          <div className={styles.divinfoDateBox2}>
            <b className={styles.b2}>31</b>
            <b className={styles.dec}>MAY</b>
            <div className={styles.pm}>5:00 PM</div>
          </div>
          <img className={styles.icon} alt="" src="/48027753@2x.png" />
          <div className={styles.divcontentRight}>
            <div
              className={styles.atlanta2024International}
            >{`ACEC Supply Chain and Manufacturing Lectures `}</div>
          </div>
        </div>
        <div className={styles.eventcard4}>
          <div className={styles.divinfoDateBox}>
            <b className={styles.b}>03</b>
            <b className={styles.dec}>Jun</b>
            <div className={styles.am}>21:00 PM</div>
          </div>
          <img className={styles.icon} alt="" src="/48027754@2x.png" />
          <div className={styles.divcontentRight}>
            <div
              className={styles.atlanta2024International}
            >{`ACEC Southern Night Talk `}</div>
          </div>
        </div>
      </div>
      <Footer2 productId="22x22x2389205572" />
    </div>
  );
};

export default EventPage;
