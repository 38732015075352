import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeContainer from "../components/HomeContainer";
import Footer from "../components/Footer";
import styles from "./Event1DetailPage.module.css";
const Event1DetailPage = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  return (
    <div className={styles.event1detailpage}>
      <HomeContainer
        headerMenuOptionsRowBackgroundImage={`url("/headerlogo6.svg")`}
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
      />
      <div className={styles.eventcontentpage}>
        <div className={styles.eventtitlepostdate}>
          <div className={styles.eventtitle}>ACEC田园篝火狂欢</div>
          <div className={styles.posteddate}>2023年7月22/23日 10:00 AM</div>
        </div>
        <img
          className={styles.eventmainimageIcon}
          alt=""
          src="/eventmainimage1@2x.png"
        />
        <div className={styles.eventtimelocation}>
          <div className={styles.time}>
            <div className={styles.wrapper}>
              <b className={styles.b}>活动时间</b>
            </div>
            <div className={styles.acec5453WillContainer}>
              <p className={styles.am}>7/22 :10:00am会员及家属签到</p>
              <p className={styles.am}>7/23:中午退房</p>
            </div>
          </div>
          <div className={styles.time}>
            <div className={styles.wrapper}>
              <b className={styles.b}>Fee</b>
            </div>
            <div className={styles.zelle6785708663}>
              <p className={styles.am}>会员免费，家属$80/人，孩子$40/人</p>
              <p className={styles.am}>付费：Zelle：678-570-8663</p>
            </div>
          </div>
          <div className={styles.time}>
            <div className={styles.wrapper}>
              <b className={styles.location1}>Location</b>
            </div>
            <div className={styles.acec5453WillContainer}>
              <p className={styles.am}>青青度假村（ACEC会员企业）</p>
              <p className={styles.am}>
                5453 Will Wheeler Rd Murrayville，GA 30564
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer productId="/frame-19925.svg" />
    </div>
  );
};

export default Event1DetailPage;
