import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeContainer from "../components/HomeContainer";
import Footer from "../components/Footer";
import styles from "./Event3DetailPage.module.css";
const Event3DetailPage = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  const onACECHttpswwClick = useCallback(() => {
    window.open("https://www.jasgeorgia.org/event-5257305");
  }, []);

  return (
    <div className={styles.event3detailpage}>
      <HomeContainer
        headerMenuOptionsRowBackgroundImage={`url("/headerlogo4.svg")`}
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
      />
      <div className={styles.eventcontentpage}>
        <div className={styles.eventtitlepostdate}>
          <div className={styles.eventtitle}>
            ACEC 五月供应链及制造业专题讲座
          </div>
          <div className={styles.posteddate}>五月31日，周三下午5：30</div>
        </div>
        <div className={styles.agenda}>
          <b className={styles.organizer}>Agenda:</b>
          <div className={styles.toyotaVpJoshContainer}>
            <p className={styles.toyotaVpJosh}>
              请到Toyota VP， Josh Smith， Honeywell 高管Patrick Winkler 及East
              West Manufacturing VP Steven Lustig 给大家提供讲座，
            </p>
            <p className={styles.toyotaVpJosh}>
              Georgia Tech的Charles Easley
              教授为大家主持，同时也有与日本及香港兄弟商会会员交流经验的机会。
            </p>
            <p className={styles.toyotaVpJosh}>感谢会员Mina 的筹备和分享</p>
          </div>
        </div>
        <div className={styles.agenda}>
          <b className={styles.organizer}>Notes:</b>
          <a
            className={styles.acecHttpswwContainer}
            href="https://www.jasgeorgia.org/event-5257305"
            target="_blank"
            onClick={onACECHttpswwClick}
          >
            <p className={styles.toyotaVpJosh}>报名请在ACEC群里接龙，</p>
            <p className={styles.toyotaVpJosh}>并在以下网站注册：</p>
            <p className={styles.toyotaVpJosh}>
              https://www.jasgeorgia.org/event-5257305
            </p>
            <p className={styles.toyotaVpJosh}>
              注册时请选择ACEC会员，报名费用为$20 （非会员费用为$30)
            </p>
          </a>
        </div>
        <div className={styles.eventtimelocation}>
          <div className={styles.time}>
            <div className={styles.timeWrapper}>
              <b className={styles.time1}>Time</b>
            </div>
            <div className={styles.div}>{`2023年5月31日 下午5:30 `}</div>
          </div>
          <div className={styles.time}>
            <div className={styles.timeWrapper}>
              <b className={styles.time1}>Location</b>
            </div>
            <div className={styles.div}>
              <p className={styles.toyotaVpJosh}>{`Kettlerock Brewing `}</p>
              <p className={styles.toyotaVpJosh}>
                (6025 Peachtree Pkwy Ste 1, Norcross, GA 30092)
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer productId="/frame-19923.svg" />
    </div>
  );
};

export default Event3DetailPage;
