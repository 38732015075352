import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ProfilePage from "./pages/ProfilePage";
import PastEventPage from "./pages/PastEventPage";
import LoginPage from "./pages/LoginPage";
import EventDetail4Page from "./pages/EventDetail4Page";
import Event3DetailPage from "./pages/Event3DetailPage";
import Event2DetailPage from "./pages/Event2DetailPage";
import Event1DetailPage from "./pages/Event1DetailPage";
import EventPage from "./pages/EventPage";
import MemberPage from "./pages/MemberPage";
import AboutUsPage from "./pages/AboutUsPage";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/profilepage":
        title = "";
        metaDescription = "";
        break;
      case "/pasteventpage":
        title = "";
        metaDescription = "";
        break;
      case "/loginpage":
        title = "";
        metaDescription = "";
        break;
      case "/eventdetail4page":
        title = "";
        metaDescription = "";
        break;
      case "/event3detailpage":
        title = "";
        metaDescription = "";
        break;
      case "/event2detailpage":
        title = "";
        metaDescription = "";
        break;
      case "/event1detailpage":
        title = "";
        metaDescription = "";
        break;
      case "/eventpage":
        title = "";
        metaDescription = "";
        break;
      case "/memberpage":
        title = "";
        metaDescription = "";
        break;
      case "/aboutuspage":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/profilepage" element={<ProfilePage />} />
      <Route path="/pasteventpage" element={<PastEventPage />} />
      <Route path="/loginpage" element={<LoginPage />} />
      <Route path="/eventdetail4page" element={<EventDetail4Page />} />
      <Route path="/event3detailpage" element={<Event3DetailPage />} />
      <Route path="/event2detailpage" element={<Event2DetailPage />} />
      <Route path="/event1detailpage" element={<Event1DetailPage />} />
      <Route path="/eventpage" element={<EventPage />} />
      <Route path="/memberpage" element={<MemberPage />} />
      <Route path="/aboutuspage" element={<AboutUsPage />} />
    </Routes>
  );
}
export default App;
