import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HeaderContainer from "../components/HeaderContainer";
import EventsContainer from "../components/EventsContainer";
import Footer2 from "../components/Footer";
import styles from "./LandingPage.module.css";
const LandingPage = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/aboutuspage");
  }, [navigate]);

  const onPastEventClick = useCallback(() => {
    navigate("/pasteventpage");
  }, [navigate]);

  const onCardImage21Click = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  return (
    <div className={styles.landingpage}>
      <HeaderContainer
        eventText="Past Event"
        eventId="/hamburger2.svg"
        headerMenuOptionsRowBackgroundImage={`url("/headerlogo10.svg")`}
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
        onAboutUsClick={onAboutUsClick}
        onPastEventsClick={onPastEventClick}
      />
      <div className={styles.herosectionnew}>
        <div className={styles.featuredDestinationCopywriti}>
          <label className={styles.acectitle}>
            Atlanta Chinese Entrepreneurs Club
          </label>
          <div className={styles.acecdesc}>
            <p className={styles.theAtlantaChinese}>
              The Atlanta Chinese Entrepreneurs Club (ACEC) is a non-profit
              organization founded in 2015 that serves as a platform for Chinese
              entrepreneurs and professionals in the Atlanta metropolitan area.
              Our goal is to build a vibrant community and provide resources for
              members to succeed in their business and career endeavors.
            </p>
          </div>
        </div>
        <div className={styles.herocityimage}>
          <img
            className={styles.cityimageIcon}
            alt=""
            src="/unsplash87jymb9zfu@2x.png"
          />
        </div>
      </div>
      <div className={styles.videosection}>
        <iframe
          className={styles.youtubeviewer}
          src={`https://www.youtube.com/embed/l_WWkfSHxMQ?rel=0&controls=0&autoplay=1&mute=1`}
          frameBorder="0"
          allowFullScreen
        />
        <div className={styles.youtubedescription}>
          <div className={styles.featuredDestinationCopywriti1}>
            <label className={styles.featuredDestinationTitle}>
              The 5th anniversary gala of the ACEC
            </label>
          </div>
        </div>
      </div>
      <div className={styles.mainsection}>
        <div className={styles.card1}>
          <img
            className={styles.cardImage1}
            alt=""
            src="/card-image-11@2x.png"
          />
          <div className={styles.cardDetails1}>
            <label className={styles.chineseNewYear}>
              Chinese New Year Gala
            </label>
            <div className={styles.cardDescription1Container}>
              <p className={styles.theAtlantaChinese}>
                On February 4th, 2023, ACEC hosted the Chinese New Year Gala at
                Royal China, Duluth, which is the most important holiday in
                Chinese culture to celebrate with family and friends. We were
                honored to have Nicole Hendrickson, Gwinnett County Chairwoman,
                Gwinnett Chamber representatives Megan Lesko, Jill Edwards, and
                Paul Oh, Katherine Lafourcade from the French American Chamber
                of Commerce of Atlanta, Tim Le and Erica Nguyen from the Georgia
                Vietnamese American Chamber of Commerce, and Sheetal Desai from
                the US Pan Asian Chamber of Commerce for the Southeast in
                attendance to celebrate with us. The gala featured traditional
                activities such as lion dances, red envelope gifting, and a
                country rock music performance.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.card1}>
          <img
            className={styles.cardImage1}
            alt=""
            src="/card-image-2@2x.png"
          />
          <div className={styles.cardDetails1}>
            <label className={styles.chineseNewYear}>Economy</label>
            <a
              className={styles.cardDescription2Container}
              href="https://www.cnbc.com/2023/03/09/us-says-it-doesnt-want-to-separate-its-economy-from-chinas.html"
              target="_blank"
            >
              <p className={styles.theAtlantaChinese}>
                The U.S. is pushing back on the idea it wants to suppress China
                and said it doesn’t want to separate the two economies,
                according to a State Department spokesperson’s comments.
              </p>
              <p className={styles.theAtlantaChinese}>&nbsp;</p>
              <p className={styles.theAtlantaChinese}>
                The spokesperson was responding to a CNBC request for comment on
                Chinese Foreign Minister remarks Tuesday.
              </p>
              <p className={styles.theAtlantaChinese}>&nbsp;</p>
              <p className={styles.theAtlantaChinese}>
                The minister said the U.S. needed to “hit the brake” to prevent conflict
                with China
              </p>
              <p className={styles.theAtlantaChinese}>&nbsp;</p>
              <p className={styles.theAtlantaChinese}>News story at CNBC</p>
            </a>
          </div>
        </div>
        <div className={styles.card1}>
          <img
            className={styles.cardImage21}
            alt=""
            src="/card-image-21@2x.png"
            onClick={onCardImage21Click}
          />
          <div className={styles.cardDetails3}>
            <label className={styles.chineseNewYear}>Join Us</label>
            <div className={styles.cardDescription1Container}>
              <p className={styles.theAtlantaChinese}>
                At our club, we believe in fostering a supportive and inclusive
                community where members can connect, learn, and grow together.
                Our events, webinars, and thought leadership content provide
                opportunities for members to engage with experts, exchange
                ideas, and stay informed on the latest trends and developments
                in the industry.
              </p>
              <p className={styles.theAtlantaChinese}>&nbsp;</p>
              <p className={styles.theAtlantaChinese}>
                Become a member of ACEC will enjoy all the benefits.
              </p>
            </div>
          </div>
        </div>
      </div>
      <EventsContainer />
      <div className={styles.logoslidesection}>
        <img
          className={styles.logoslidesectionChild}
          alt=""
          src="/acec_logo_slide.gif"
        />
      </div>
      <Footer2 productId="/frame-19929.svg" />
    </div>
  );
};

export default LandingPage;
