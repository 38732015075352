import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeContainer from "../components/HomeContainer";
import Footer from "../components/Footer";
import styles from "./AboutUsPage.module.css";
const AboutUsPage = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  return (
    <div className={styles.aboutuspage}>
      <HomeContainer
        headerMenuOptionsRowBackgroundImage={`url("/headerlogo9.svg")`}
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
      />
      <div className={styles.aboutussection}>
        <img
          className={styles.storyImageIcon}
          alt=""
          src="/unsplashc-zhkgezy3u@2x.png"
        />
        <div className={styles.missionsectionParent}>
          <div className={styles.missionsection}>
            <div className={styles.aboutUs}>About Us</div>
            <div className={styles.theAtlantaChineseContainer}>
              <p className={styles.theAtlantaChinese}>
                The Atlanta Chinese Entrepreneurs Club (ACEC) is a non-profit
                organization founded in 2015 that serves as a platform for
                Chinese entrepreneurs and professionals in the Atlanta
                metropolitan area. Our goal is to build a vibrant community and
                provide resources for members to succeed in their business and
                career endeavors.
              </p>
            </div>
          </div>
          <div className={styles.missionsection}>
            <div className={styles.aboutUs}>Our Mission</div>
            <div className={styles.theAtlantaChineseContainer}>
              <p className={styles.theAtlantaChinese}>
                Our mission is to facilitate communication, collaboration, and
                growth among Chinese entrepreneurs and professionals in Atlanta
                by providing a platform for networking, education, and
                mentorship. We strive to create an inclusive environment that
                supports and empowers our members to achieve their goals and
                contribute to the local economy and society. Through our events
                and programs, we aim to foster entrepreneurship, innovation, and
                cross-cultural exchange, and to promote the values of integrity,
                respect, and excellence in business and beyond.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer productId="/frame-19928.svg" />
    </div>
  );
};

export default AboutUsPage;
