import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeContainer from "../components/HomeContainer";
import Footer from "../components/Footer";
import styles from "./Event2DetailPage.module.css";
const Event2DetailPage = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  return (
    <div className={styles.event2detailpage}>
      <HomeContainer
        headerMenuOptionsRowBackgroundImage={`url("/headerlogo5.svg")`}
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
      />
      <div className={styles.eventcontentpage}>
        <div className={styles.eventtitlepostdate}>
          <div className={styles.eventtitle}>ACEC财富沙龙</div>
          <div className={styles.posteddate}>2023年5月20日（周六）</div>
        </div>
        <div className={styles.agenda}>
          <b className={styles.organizer}>Agenda:</b>
          <div className={styles.pm1115Container}>
            <p className={styles.pmJohn}>
              12-1:15pm 午餐+新老会员交流会（自我介绍/1分钟/人）
            </p>
            <p className={styles.pmJohn}>
              1:15-1:45pm 经济和市场走势 (John Brogan， Director, Black Rock)
            </p>
            <p className={styles.pmJohn}>
              1:45-2:15pm 企业家的工具箱 (Dan Zhang, Senior Vice President,
              Morgan Stanley)
            </p>
            <p className={styles.pmJohn}>
              2:15-2:45pm 企业和私募股权投资 (Sam Patsy, CFA, Partners Group)
            </p>
            <p className={styles.pmJohn}>{`2:45 -3:00 Q&A
`}</p>
            <p className={styles.pmJohn}>3:00-4:00 自由交流</p>
          </div>
        </div>
        <div className={styles.eventtimelocation}>
          <div className={styles.time}>
            <div className={styles.timeWrapper}>
              <b className={styles.time1}>Time</b>
            </div>
            <div
              className={styles.div}
            >{`2023年5月20日（周六）下午12-4:00点 `}</div>
          </div>
          <div className={styles.time}>
            <div className={styles.timeWrapper}>
              <b className={styles.time1}>Location</b>
            </div>
            <div className={styles.div}>
              <p className={styles.pmJohn}>摩根士丹利20楼会议室</p>
              <p
                className={styles.pmJohn}
              >{`5 Concourse Pkwy, Suite 2000, `}</p>
              <p className={styles.pmJohn}>Atlanta, GA 30328</p>
            </div>
          </div>
        </div>
      </div>
      <Footer productId="/frame-19924.svg" />
    </div>
  );
};

export default Event2DetailPage;
