import styles from "./EventTimeLocationContainer.module.css";

const EventTimeLocationContainer = () => {
  return (
    <div className={styles.eventtimelocation}>
      <div className={styles.time}>
        <div className={styles.timeWrapper}>
          <b className={styles.host}>Time</b>
        </div>
        <div className={styles.pm}>12/30/2023 7:00PM</div>
      </div>
      <div className={styles.time}>
        <div className={styles.timeWrapper}>
          <b className={styles.host}>Location</b>
        </div>
        <div className={styles.pm}>
          <p className={styles.gasSouthTheater}>Gas South Theater</p>
          <p className={styles.gasSouthTheater}>
            6400 Sugarloaf Pkwy, Duluth, GA 30097
          </p>
        </div>
      </div>
      <div className={styles.time}>
        <div className={styles.timeWrapper}>
          <b className={styles.host}>Host</b>
        </div>
        <div className={styles.pm}>
          ACEC
        </div>
      </div>
      <div className={styles.time}>
        <div className={styles.timeWrapper}>
          <b className={styles.host}>Co-Host</b>
        </div>
        <div className={styles.pm}>Asian Arts Alliance</div>
      </div>
    </div>
  );
};

export default EventTimeLocationContainer;
