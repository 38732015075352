import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HomeContainer from "../components/HomeContainer";
import EventTimeLocationContainer from "../components/EventTimeLocationContainer";
import Footer from "../components/Footer";
import styles from "./EventDetail4Page.module.css";

const EventDetail4Page = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  const onEventContentPageClick = useCallback(() => {
    window.open(
      "https://www.eventbrite.com/e/mothers-day-dazzling-music-night-tickets-622113858297"
    );
  }, []);

  return (
    <div className={styles.eventdetail4page}>
      <HomeContainer
        headerMenuOptionsRowBackgroundImage="url('/aceclogo@3x.png')"
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
      />
      <div
        className={styles.eventcontentpage}
        onClick={onEventContentPageClick}
      >
        <div className={styles.eventtitlepostdate}>
          <div className={styles.eventtitle}>
            ATLANTA 2024 International New Year Concert
          </div>
          <div className={styles.posteddate}>2023年12月30日</div>
        </div>
        <img
          className={styles.shutterstock3814610111Icon}
          alt=""
          src="/a.png"
        />
        <div className={styles.eventcontent}>
          <div className={styles.atlanta2024International}>
            ATLANTA 2024 International New Year Concert
          </div>
          <div className={styles.acecAndAsianContainer}>
            <p className={styles.acecAndAsian}>
              ACEC and Asian Arts Alliance will be co-hosting the 2024 Atlanta
              International New Year Concert at the Gas South Theater on Dec. 30
              at 7pm. The concert represent a collaboration among various ethnic
              groups, aiming to bring together local minority communities to
              celebrate the new year of 2024! The event features a lineup of
              various talented groups and artists.
            </p>
            <p className={styles.acecAndAsian}>&nbsp;</p>
            <p className={styles.acecAndAsian}>
              ACEC和Asian Art Alliance 将共同举办2024年亚特兰大国际新年音乐会,
              将于12月30日晚7点在Gas
              South剧院举行。这场音乐会代表了各民族之间的合作，旨在将当地少数民族社区聚集在一起，共同庆祝2024年的新年！这次活动的特点是各种有才华的团体和艺术家的阵容。
            </p>
          </div>
        </div>
        <EventTimeLocationContainer />
      </div>
      <Footer productId="/frame-19924.svg" />
    </div>
  );
};

export default EventDetail4Page;
