import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate, useLocation } from 'react-router-dom';

const protectedRoute = (Comp, route = '/loginpage') => (props) => {
  console.log("inside protected route")
  let navigate = useNavigate();
  let location = useLocation();
  async function checkAuthState() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log(user)
    } catch (err) {

      navigate(route,
        {
          replace: true,
          state: {
            from: location
          }
        })
      console.log(err);
    }
  }
  useEffect(() => {
    checkAuthState()
  }, [])
  return <Comp {...props} />
}

export default protectedRoute