import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./DrawerMenu.module.css";
const DrawerMenu = ({ onClose }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onMenuItemClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onMenuItem1Click = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMenuItem2Click = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  const onMenuItem3Click = useCallback(() => {
    navigate("/aboutuspage");
  }, [navigate]);

  const onMenuItem4Click = useCallback(() => {
    navigate("/pasteventpage");
  }, [navigate]);

  const onMenuItem5Click = useCallback(() => {
    navigate("/loginpage");
  }, [navigate]);

  return (
    <div className={styles.drawerMenu} data-animate-on-scroll>
      <div className={styles.menu}>
        <div className={styles.menuChild} />
        <div className={styles.drawerMenuItems}>
          <button className={styles.menuItem} onClick={onMenuItemClick}>
            <div className={styles.home}>Home</div>
          </button>
          <button className={styles.menuItem} onClick={onMenuItem1Click}>
            <div className={styles.home}>Events</div>
          </button>
          <button className={styles.menuItem} onClick={onMenuItem2Click}>
            <div className={styles.home}>Membership</div>
          </button>
          <button className={styles.menuItem3} onClick={onMenuItem3Click}>
            <div className={styles.home}>About Us</div>
          </button>
          <button className={styles.menuItem3} onClick={onMenuItem4Click}>
            <div className={styles.home}>Past Event</div>
          </button>
          <button className={styles.menuItem3} onClick={onMenuItem5Click}>
            <div className={styles.home}>Login</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DrawerMenu;
