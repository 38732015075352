import styles from "./LoggedInContainer.module.css";
const LoggedInContainer = () => {
  return (
    <div className={styles.loginframe}>
      <div className={styles.namesection}>
        <div className={styles.firstname}>
          <div className={styles.firstnamelabel}>
            <div className={styles.firstName}>First Name</div>
          </div>
          <div className={styles.firstnamelabel}>
            <input
              className={styles.input}
              type="text"
              placeholder="Your name"
              disabled
            />
          </div>
        </div>
        <div className={styles.firstname}>
          <div className={styles.firstnamelabel}>
            <div className={styles.firstName}>Last Name</div>
          </div>
          <div className={styles.firstnamelabel}>
            <input
              className={styles.input}
              type="text"
              placeholder="Your name"
              disabled
            />
          </div>
        </div>
      </div>
      <div className={styles.phonenumberframe}>
        <div className={styles.firstnamelabel}>
          <div className={styles.firstName}>Phone Number</div>
        </div>
        <div className={styles.firstnamelabel}>
          <input
            className={styles.input}
            type="text"
            placeholder="Your name"
            disabled
          />
        </div>
      </div>
      <div className={styles.phonenumberframe}>
        <div className={styles.firstnamelabel}>
          <div className={styles.firstName}>Email</div>
        </div>
        <div className={styles.firstnamelabel}>
          <input
            className={styles.input}
            type="text"
            placeholder="Your name"
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default LoggedInContainer;
