import { useState, useMemo, useCallback } from "react";
import DrawerMenu from "./DrawerMenu";
import PortalDrawer from "./PortalDrawer";
import styles from "./HeaderContainer.module.css";
const HeaderContainer = ({
  eventText,
  eventId,
  headerMenuOptionsRowBackgroundImage,
  onHomeClick,
  onEventsClick,
  onMembershipClick,
  onAboutUsClick,
  onPastEventsClick,
}) => {
  const aCECLogoStyle = useMemo(() => {
    return {
      backgroundImage: headerMenuOptionsRowBackgroundImage,
    };
  }, [headerMenuOptionsRowBackgroundImage]);

  const [isDrawerMenuOpen, setDrawerMenuOpen] = useState(false);

  const openDrawerMenu = useCallback(() => {
    setDrawerMenuOpen(true);
  }, []);

  const closeDrawerMenu = useCallback(() => {
    setDrawerMenuOpen(false);
  }, []);

  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.headerElementsRow}>
          <button className={styles.aceclogo} style={aCECLogoStyle} />
          <div className={styles.headerMenuOptionsRow}>
            <div className={styles.headerLinks}>
              <button className={styles.home} onClick={onHomeClick}>
                Home
              </button>
              <button className={styles.events} onClick={onEventsClick}>
                Events
              </button>
              <button className={styles.events} onClick={onMembershipClick}>
                Membership
              </button>
              <button className={styles.aboutUs} onClick={onAboutUsClick}>
                About Us
              </button>
              <button className={styles.aboutUs} onClick={onPastEventsClick}>
                {eventText}
              </button>
            </div>
            <div
              className={styles.headerHamburgerMenu}
              onClick={openDrawerMenu}
            >
              <div className={styles.hamburgerBackground} />
              <img className={styles.hamburgerIcon} alt="" src={eventId} />
            </div>
            <div className={styles.seperator} />
            <button className={styles.headerSearchIcon}>
              <img className={styles.vectorIcon} alt="" src="/vector.svg" />
            </button>
          </div>
        </div>
      </div>
      {isDrawerMenuOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeDrawerMenu}
        >
          <DrawerMenu onClose={closeDrawerMenu} />
        </PortalDrawer>
      )}
    </>
  );
};

export default HeaderContainer;
