import styles from "./EventCardContainer.module.css";
const EventCardContainer = () => {
  return (
    <div className={styles.pasteventcard}>
      <img className={styles.cardImage1} alt="" src="/card-image-1@2x.png" />
      <div className={styles.cardDetails1}>
        <label className={styles.chineseNewYear}>Chinese New Year Gala</label>
        <label className={styles.cardDescription1Container}>
          <p
            className={styles.onFebruary4th}
          >{`On February 4th, 2023, ACEC hosted the Chinese New Year Gala at Royal China, Duluth, which is the `}</p>
        </label>
      </div>
    </div>
  );
};

export default EventCardContainer;
