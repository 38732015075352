import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HeaderContainer from "../components/HeaderContainer";

import Footer from "../components/Footer";
import styles from "./ProfilePage.module.css";
import protectedRoute from '../protectedRoute'
import LoggedInContainer from "../components/LoggedInContainer";

const ProfilePage = () => {
  const navigate = useNavigate();


  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/aboutuspage");
  }, [navigate]);

  const onPastEventsClick = useCallback(() => {
    navigate("/pasteventpage");
  }, [navigate]);

  return (
    <div className={styles.profilepage}>
      <HeaderContainer
        eventText="Past Events"
        eventId="/hamburger.svg"
        headerMenuOptionsRowBackgroundImage={`url("/headerlogo.svg")`}
        onHomeClick={onHomeClick}
        onEventsClick={onEventsClick}
        onMembershipClick={onMembershipClick}
        onAboutUsClick={onAboutUsClick}
        onPastEventsClick={onPastEventsClick}
      />
      <div className={styles.profileinfosection}>
        <LoggedInContainer />
      </div>
      <Footer productId="/frame-1992.svg" />
    </div>

  );
};

export default protectedRoute(ProfilePage);
