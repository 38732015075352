import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EventsContainer.module.css";

const EventsContainer = () => {
  const navigate = useNavigate();

  const onViewAllEventButtonClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onEventThumbnailImageClick = useCallback(() => {
    window.open("https://web.gwinnettchamber.org/events");
  }, []);

  const onEventCard1Click = useCallback(() => {
    navigate("/eventdetail4page");
  }, [navigate]);

  const onEventCard2Click = useCallback(() => {
    navigate("/event3detailpage");
  }, [navigate]);

  return (
    <div className={styles.eventssection}>
      <div className={styles.eventsheader}>
        <b className={styles.events}>Events</b>
        <button
          className={styles.viewalleventbutton}
          onClick={onViewAllEventButtonClick}
        >
          <div className={styles.viewAllEvents}>View All Events</div>
          <img className={styles.arrowRightIcon} alt="" src="/arrowright.svg" />
        </button>
      </div>
      <div className={styles.eventsframe}>
        <div className={styles.eventcard}>
          <img
            className={styles.eventthumbnailIcon}
            alt=""
            src="/card-image-1@2x.png"
            onClick={onEventThumbnailImageClick}
          />
          <div className={styles.eventdescriptionsection}>
            <div className={styles.eventdate}>
              <b className={styles.sept}>Nov</b>
            </div>
            <div className={styles.eventdesc}>
              <b className={styles.eventtitle}>Gwinnett Chamber Events</b>
              <div className={styles.eventdescription}>
                ACEC Members feel free to register
              </div>
            </div>
          </div>
        </div>
        <div className={styles.eventcard1} onClick={onEventCard1Click}>
          <img
            className={styles.eventthumbnailIcon1}
            alt=""
            src="/card-image-12@2x.png"
          />
          <div className={styles.eventdescriptionsection}>
            <div className={styles.eventdate1}>
              <b className={styles.sept}>Dec</b>
              <b className={styles.b}>30</b>
            </div>
            <div className={styles.eventdesc}>
              <b className={styles.eventtitle}>2024 New Year Concert</b>
              <div className={styles.eventdescription1}>
                ATLANTA 2024 International New Year Concert
              </div>
            </div>
          </div>
        </div>
        <div className={styles.eventcard2} onClick={onEventCard2Click}>
          <img
            className={styles.eventthumbnailIcon1}
            alt=""
            src="/card-image-14@2x.png"
          />
          <div className={styles.eventdescriptionsection}>
            <div className={styles.eventdate1}>
              <b className={styles.sept}>MAY</b>
              <b className={styles.b}>31</b>
            </div>
            <div className={styles.eventdesc}>
              <b className={styles.eventtitle}>
                ACEC Supply Chain and Manufacturing
              </b>
              <div className={styles.eventdescription1}>
                Seminar on supply chain and manufacturing in May
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsContainer;
