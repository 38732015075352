import { useState, useCallback, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderContainer from "../components/HeaderContainer";
import Footer from "../components/Footer";
import styles from "./LoginPage.module.css";

import { Auth } from 'aws-amplify'
import PhoneInput from 'react-phone-number-input'
//-------------------------------
const NOTSIGNIN = 'Enter Your Phone Number:';
const SIGNEDIN = 'You have logged in successfully';
const SIGNEDOUT = 'You have logged out successfully';
const WAITINGFOROTP = 'Enter Verification Code:';
const VERIFYNUMBER = 'Verifying number (Country code +XX needed)';
//------------------
const LoginPage = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState('Enter Your Phone Number:');
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [otp, setOtp] = useState('');
  const [number, setNumber] = useState('');
  const [phone, setPhone] = useState("");

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onEventsClick = useCallback(() => {
    navigate("/eventpage");
  }, [navigate]);

  const onMembershipClick = useCallback(() => {
    navigate("/memberpage");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/aboutuspage");
  }, [navigate]);

  const onPastEventClick = useCallback(() => {
    navigate("/pasteventpage");
  }, [navigate]);


  const handleOnChange = value => {
    setPhone(value);
  };

  let location = useLocation();

  useEffect(() => {
    checkUser()
  }, [])
  let from = location.state?.from?.pathname || "/";

  async function checkUser() {
    try {
      const data = await Auth.currentAuthenticatedUser();
      const userInfo = { username: data.username, ...data.attributes }
      setUser(userInfo);
      console.log('checkUser=', userInfo);
      setMessage(SIGNEDIN);
      setSession(null);
    } catch (err) {
      console.error(err);
      setMessage(NOTSIGNIN);
    }
  };


  const signIn = () => {
    console.log(phone);
    const RegEx = /^(\+)|\D/g
    const cleanedPhone = `+${phone.replace(RegEx, '')}`;
    console.log(cleanedPhone);
    console.log(number)
    //setMessage(VERIFYNUMBER);
    Auth.signIn(cleanedPhone)
      .then((result) => {
        setSession(result);
        console.log('signin=', result)
        setMessage(WAITINGFOROTP);
      })
      .catch((e) => {
        if (e.code === 'UserNotFoundException') {
          //signUp();
          console.log('SignIn:User not found')
        } else if (e.code === 'UsernameExistsException') {
          console.log('SignIn:userexist try again')
          setMessage(WAITINGFOROTP);
          signIn();
        } else {
          console.log(e.code);
          console.error(e);
        }
      });
  };

  async function verifyOTP() {
    try {
      const data = await Auth.sendCustomChallengeAnswer(session, otp);
      console.log('cognito user data', data);
      const currentSession = await Auth.currentSession();
      console.log('coginotUserIdentityToken=', currentSession.getIdToken().getJwtToken());
      setUser(data);
      setMessage(SIGNEDIN);
      setSession(null);
    } catch (err) {
      console.log('verifyOTP= not match');
      signIn();
      setMessage("验证码错误");
      setOtp('');
      console.log(err);
    }
  }

  if (user) {
    console.log("from=", from)
    navigate(from, { replace: true });
  }
  return (
    <>
      <div className={styles.loginpage}>
        <HeaderContainer
          eventText="Past Event"
          eventId="/hamburger1.svg"
          headerMenuOptionsRowBackgroundImage={`url("/headerlogo2.svg")`}
          onHomeClick={onHomeClick}
          onEventsClick={onEventsClick}
          onMembershipClick={onMembershipClick}
          onAboutUsClick={onAboutUsClick}
          onPastEventsClick={onPastEventClick}
        />
        <div className={styles.loginform}>
          <div className={styles.loginframe}>
            <div className={styles.loginmessage}>
              <div className={styles.enterYourPhone}>{message}</div>
            </div>
            {!user && !session && (
              <Fragment>
                <div className={styles.loginmessage}>

                  <PhoneInput
                    containerStyle={styles.input}
                    enableAreaCodes={false}
                    onlyCountries={['US']}
                    defaultCountry={"US"}

                    placeholder="Your Phone Number"
                    onChange={handleOnChange}
                  />
                </div>
                <button className={styles.submit} onClick={signIn}>
                  <div className={styles.unstyledbutton}>
                    <div className={styles.button}>Send Code</div>
                  </div>
                </button>

              </Fragment>
            )}
            {!user && session && (
              <Fragment>
                <div className={styles.loginmessage}>
                  <input className={styles.input} type="text" onChange={(event) => setOtp(event.target.value)} placeholder="verfication code" />
                </div>
                <button className={styles.submit} onClick={verifyOTP}>
                  <div className={styles.unstyledbutton}>
                    <div className={styles.button}>Verify</div>
                  </div>
                </button>
              </Fragment>
            )}
          </div>
        </div>
        <Footer productId="/frame-19921.svg" />
      </div>
    </>
  );
};

export default LoginPage;
